import React, { useState, useEffect, useRef, useCallback } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from './../../../firebaseConfig';
import '../../../App.css';
import TalentCalendar from '../../TalentCalendar';
import edit from './../../../images/edit.svg';
import calendarIcon from './../../../images/calendar.svg';
import closeButton from './../../../images/close.svg';
import Select from 'react-select';
import { customStyles } from '../../../helpers/StyleUtils';

function TalentListPlaniing() {
  const [search, setSearch] = useState(""); // New state for search term
  const [selectedTalent, setSelectedTalent] = useState(null);
  const [talents, setTalents] = useState([]);
  const [currentTalent, setCurrentTalent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedFunction, setSelectedFunction] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentName, setCurrentName] = useState('');
  const [currentFunction, setCurrentFunction] = useState('');
  const [currentSurname, setCurrentSurname] = useState('');
  const [currentPhone, setCurrentPhone] = useState('');
  const [currentOtherFunctions, setCurrentOtherFunctions] = useState('');
  const [currentPrice, setCurrentPrice] = useState('');
  const [sortOrder, setSortOrder] = useState(true);
  const [companyNames, setCompanyNames] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedGoodCompanies, setSelectedGoodCompanies] = useState([]);
  const [selectedBadCompanies, setSelectedBadCompanies] = useState([]);
  const [selectedtryoutCompanies, setSelectedtryoutCompanies] = useState([]);
  const [notesProductLeads, setNotesProductLeads] = useState('');
  const [notesProductLeadsdd, setNotesProductLeadsdd] = useState('');

  const [filterGoodCompanies, setfilterGoodCompanies] = useState([]);
  const [filterBadCompanies, setfilterBadCompanies] = useState([]);
  const [filtertryoutCompanies, setfilterTyoutCompanies] = useState([]);
  const [filterLocation, setfilterloation] = useState([]);

  
  const fetchCompanyNames = async () => {
    const companySnap = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company"));  // Replace with the correct path
    const companyData = companySnap.docs.map(doc => doc.data().name);  // Assuming each document has a "name" field
    setCompanyNames(companyData);
  };
  const fetchlocations = async () => {
    const locationSnap = await getDocs(
      collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/locations")
    );
    const locationData = locationSnap.docs.map((doc) => doc.data().short);
    setLocations(locationData);
  };
  const fetchTalents = async () => {
    const talentSnap = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data"));
    const talentData = talentSnap.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setTalents(talentData);
  };
  useEffect(() => {
    fetchTalents();
    fetchCompanyNames();
    fetchlocations();
  }, []);
  const deleteTalent = async (id) => {
    await deleteDoc(doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data", id));
    fetchTalents();
  };
  const notesOption = [
    {value: 'LEGER', label:'LEGER'},
    {value: 'HÄNGER', label:'HÄNGER'},

    {value: 'BÜSTE', label:'BÜSTE'},
    {value: 'ACC', label:'ACC'}
  ]
  const editTalent = (talent) => {
    setIsEditing(true);
    setCurrentTalent(talent);
    setCurrentEmail(talent.E_Mail);
    setCurrentName(talent.Name);
    setCurrentFunction(talent.Funktion);
    setCurrentSurname(talent.Notiz_Setcast);
    setCurrentPhone(talent.Telefon);
    setCurrentOtherFunctions(talent.other_functions);
    setCurrentPrice(talent.Tagessatz);

    setSelectedBadCompanies(talent.badPerformanceCompany ? talent.badPerformanceCompany : '');
    setSelectedGoodCompanies(talent.goodPerformanceCompany ? talent.goodPerformanceCompany : '');
    setSelectedtryoutCompanies(talent.tryOutCompany ? talent.tryOutCompany : '');
    setNotesProductLeads(talent.notesProductLeads ? talent.notesProductLeads : '');
    setNotesProductLeadsdd(talent.notesProductLeadsdd ? talent.notesProductLeadsdd : '');

  };
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().slice(-2)}`;
  };
  const updateTalentDetails = async () => {
    if (currentTalent) {
      const docRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data", currentTalent.id);

      const goodCompaniesWithTimestamp = (selectedGoodCompanies || []).map(company => {
        const existingCompany = currentTalent.goodPerformanceCompany?.find(c => c.label === company.label);
        return existingCompany || {
          ...company,
          timestamp: new Date().toISOString()
        };
      });

      const badCompaniesWithTimestamp = (selectedBadCompanies || []).map(company => {
        const existingCompany = currentTalent.badPerformanceCompany?.find(c => c.label === company.label);
        return existingCompany || {
          ...company,
          timestamp: new Date().toISOString()
        };
      });

      const tryOutCompaniesWithTimestamp = (selectedtryoutCompanies || []).map(company => {
        const existingCompany = currentTalent.tryOutCompany?.find(c => c.label === company.label);
        return existingCompany || {
          ...company,
          timestamp: new Date().toISOString()
        };
      });

      await updateDoc(docRef, {
        badPerformanceCompany: badCompaniesWithTimestamp,
        goodPerformanceCompany: goodCompaniesWithTimestamp,
        tryOutCompany: tryOutCompaniesWithTimestamp,
        notesProductLeads: notesProductLeads,
        notesProductLeadsdd: notesProductLeadsdd
      });
      
      setIsEditing(false);
      fetchTalents();
    }
  };
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  }
  const handleFunctionChange = (e) => {
    setSelectedFunction(e.target.value);
  }
  const sortByName = () => {
    setTalents([...talents].sort((a, b) => {
      return sortOrder ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    }));
    setSortOrder(!sortOrder);  // Switch the sort order for the next click
  };

  const uniqueFunctions = [...new Set(talents.map(talent => talent.Funktion))];
  const functionOptions = uniqueFunctions.map(func => ({
    value: func,
    label: func
  }));
  const uniqueTypes = [...new Set(talents.map(talent => talent.Bereich))];
  const typeOptions = uniqueTypes.map(type => ({
    value: type,
    label: type
  }));

  const companyOptions = companyNames.map(company => ({
    value: company,
    label: company
})).sort((a, b) => a.label.localeCompare(b.label));


const locationOptions = locations
.map((locations) => ({
  value: locations,
  label: locations,
}))
.sort((a, b) => a.label.localeCompare(b.label));
  return (
    <section>
      <div className='content-wrapper'>
        <h1>Talent List</h1>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className='inputtext search'
          />
          
        </div>

        <div style={{ display: 'flex', marginTop: '10px' }}>

        <div style={{ marginRight: '10px' }}>
          <Select
            value={typeOptions.find(option => option.value === selectedType)}
            onChange={option => setSelectedType(option ? option.value : '')}
            options={typeOptions}
            isClearable
            placeholder="Select Type"
            styles={customStyles}

            />
        </div>
<div>
          <Select
            value={functionOptions.find(option => option.value === selectedFunction)}
            onChange={option => setSelectedFunction(option ? option.value : '')}
            options={functionOptions}
            isClearable
            placeholder="Select Function"
            styles={customStyles}
          />
          


        </div>

        <div>
            <Select
              isMulti
              value={filterGoodCompanies}
              onChange={setfilterGoodCompanies}
              options={companyOptions}
              isClearable
              placeholder="Select Good "
              styles={customStyles}
            />
          </div>
          <div>
            <Select
              isMulti
              value={filtertryoutCompanies}
              onChange={setfilterTyoutCompanies}
              options={companyOptions}
              isClearable
              placeholder="Select Try Out "
              styles={customStyles}
            />
          </div>
          <div>
            <Select
              isMulti
              value={filterBadCompanies}
              onChange={setfilterBadCompanies}
              options={companyOptions}
              isClearable
              placeholder="Select Bad "
              styles={customStyles}
            />
          </div>
          <div>
            <Select
              isMulti
              value={filterLocation}
              onChange={setfilterloation}
              options={locationOptions}
              isClearable
              placeholder="Select Location "
              styles={customStyles}
            />
          </div>
          </div>
      </div>
      <div className='content-wrapper content-wrapper-talent-list'>
        <div className="talent-list">
          <div className='talent-item-header'>
            <div style={{ flexBasis: '400px' }} >Name</div>
            <div style={{ flexBasis: '150px' }} >Type</div>
            <div style={{ flexBasis: '300px' }} >Function</div>
            <div style={{ flexBasis: '300px' }} >Good Performance</div>
            <div style={{ flexBasis: '300px' }} >Bad Performance</div>
            <div style={{ flexBasis: '300px' }} >Try Out</div>
            <div style={{ flexBasis: '300px' }} >Notes Product Leads</div>
            <div style={{ flexBasis: '100px ' }}></div>
          </div>
          {talents.sort((a, b) => {
              // Assuming 'Name' is the property you want to sort by
              const nameA = a.Name.toLowerCase(); // convert to lower case to ensure case-insensitive comparison
              const nameB = b.Name.toLowerCase(); // convert to lower case to ensure case-insensitive comparison
              if (nameA < nameB) return -1; // nameA comes first
              if (nameA > nameB) return 1; // nameB comes first
              return 0; // names are equal
            }).filter((talent) => {
            const searchLowerCase = search?.toLowerCase();
            return (
              (selectedType ? talent.Bereich === selectedType : true) &&
              (selectedFunction ? talent.Funktion === selectedFunction : true) &&
              (filterGoodCompanies &&
                Array.isArray(filterGoodCompanies) &&
                filterGoodCompanies.length > 0
                  ? talent.goodPerformanceCompany?.length > 0 &&
                    talent.goodPerformanceCompany.some((talentCompany) =>
                      filterGoodCompanies.some(
                        (selectedCompany) =>
                          selectedCompany.label === talentCompany.label
                      )
                    )
                  : true) &&
                (filterBadCompanies &&
                Array.isArray(filterBadCompanies) &&
                filterBadCompanies.length > 0
                  ? talent.badPerformanceCompany?.length > 0 &&
                    talent.badPerformanceCompany.some((talentCompany) =>
                      filterBadCompanies.some(
                        (selectedCompany) =>
                          selectedCompany.label === talentCompany.label
                      )
                    )
                  : true) &&
                (filtertryoutCompanies &&
                Array.isArray(filtertryoutCompanies) &&
                filtertryoutCompanies.length > 0
                  ? talent.tryOutCompany?.length > 0 &&
                    talent.tryOutCompany.some((talentCompany) =>
                      filtertryoutCompanies.some(
                        (selectedCompany) =>
                          selectedCompany.label === talentCompany.label
                      )
                    )
                  : true) &&
                  (filterLocation &&
                    Array.isArray(filterLocation) && filterLocation.length > 0
                      ? Array.isArray(talent.locations) && talent.locations.length > 0 &&
                        talent.locations.some(talentLocation =>
                          filterLocation.some(selectedLocation =>
                            selectedLocation.label === talentLocation.label
                          )
                        )
                      : true) &&
              (
                String(talent.Name)?.toLowerCase().includes(searchLowerCase) ||
                String(talent.Name)?.toLowerCase().includes(searchLowerCase) ||
                String(talent.Funktion)?.toLowerCase().includes(searchLowerCase) ||
                String(talent.E_Mail)?.toLowerCase().includes(searchLowerCase) 
              )
            );
          }).map((talent) => (
            <div key={talent.id} className='talent-item'>
              {isEditing && currentTalent.id === talent.id ? (
                <>  <span style={{ paddingLeft: '0' }} className='talent-item-header'>{currentName}</span>
          
                 
                  <span className='talent-item-input-title'>Good Performance Companies</span>

                  <Select
                    isMulti
                    value={selectedGoodCompanies}
                    onChange={setSelectedGoodCompanies}
                    options={companyOptions}
                    isClearable
                    placeholder="Good Performance Companies"
                    styles={customStyles}
                  />
  <span className='talent-item-input-title'>Bad Performance Companies</span>
                  <Select
                    isMulti
                    value={selectedBadCompanies}
                    onChange={setSelectedBadCompanies}
                    options={companyOptions}
                    isClearable
                    placeholder="Bad Performance Companies"
                    styles={customStyles}
                  />
                          <span className='talent-item-input-title'>Try Out Companies</span>
                       <Select
                    isMulti
                    value={selectedtryoutCompanies}
                    onChange={setSelectedtryoutCompanies}
                    options={companyOptions}
                    isClearable
                    placeholder="Try Out Companies"
                    styles={customStyles}
                  />
                   <span className="talent-item-input-title">
                   Notes Product Leads
                    </span>
                    <input
                      className="inputtext"
                      value={notesProductLeads}
                      onChange={(e) => setNotesProductLeads(e.target.value)}
                    />
                                     <Select
                
                    value={notesProductLeadsdd}
                    onChange={setNotesProductLeadsdd}
                    options={notesOption}
                    isClearable
                    placeholder="notes"
                    styles={customStyles}
                  />


                  {/* Add more input fields for other details similarly */}
                  <button className='button' onClick={updateTalentDetails} style={{ marginTop: '10px', marginBottom: '20px' }}>Save</button>
                  <button className='button' onClick={() => setIsEditing(false)} style={{ marginTop: '10px', marginBottom: '20px' }}>Cancel</button>
                </>
              ) : (<>
                <div key={talent.id} className='talent-item-firstline'>
                  <div style={{ flexBasis: '400px' }}>{talent.Name}</div>
                  <div style={{ flexBasis: '150px' }}>{talent.Bereich}</div>
                  <div style={{ flexBasis: '300px' }}>{talent.Funktion}</div>
                  <div style={{ flexBasis: '300px' }}>
                    {talent.goodPerformanceCompany && Array.isArray(talent.goodPerformanceCompany) 
                    ? talent.goodPerformanceCompany.map((company) => (
                        <div key={company.label} style={{ 
                          marginBottom: '4px',
                          padding: '2px 4px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '3px'
                        }}>
                          <strong>{company.label}</strong>
                          {company.timestamp && (
                            <div style={{ 
                              fontSize: '0.85em',
                              color: '#666',
                              marginLeft: '12px',
                              borderLeft: '2px solid #ddd',
                              paddingLeft: '6px'
                            }}>
                              Added: {formatDate(company.timestamp)}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                  </div>
                  <div style={{ flexBasis: '300px' }}>
                    {talent.badPerformanceCompany && Array.isArray(talent.badPerformanceCompany)
                    ? talent.badPerformanceCompany.map((company) => (
                        <div key={company.label} style={{ 
                          marginBottom: '4px',
                          padding: '2px 4px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '3px'
                        }}>
                          <strong>{company.label}</strong>
                          {company.timestamp && (
                            <div style={{ 
                              fontSize: '0.85em',
                              color: '#666',
                              marginLeft: '12px',
                              borderLeft: '2px solid #ddd',
                              paddingLeft: '6px'
                            }}>
                              Added: {formatDate(company.timestamp)}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                  </div>
                  <div style={{ flexBasis: '300px' }}>
                    {talent.tryOutCompany && Array.isArray(talent.tryOutCompany)
                    ? talent.tryOutCompany.map((company) => (
                        <div key={company.label} style={{ 
                          marginBottom: '4px',
                          padding: '2px 4px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '3px'
                        }}>
                          <strong>{company.label}</strong>
                          {company.timestamp && (
                            <div style={{ 
                              fontSize: '0.85em',
                              color: '#666',
                              marginLeft: '12px',
                              borderLeft: '2px solid #ddd',
                              paddingLeft: '6px'
                            }}>
                              Added: {formatDate(company.timestamp)}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                  </div>
                  <div style={{ flexBasis: '300px' }}>  
                  {talent.notesProductLeads} - {(talent.notesProductLeadsdd && talent.notesProductLeadsdd.label) || ''}

</div>
                              
                  <div style={{ flexBasis: '100px ' }}>
                    <span style={{ marginRight: '20px' }}>
                      {selectedTalent === talent.id ? (
                        <button className='edit-button' onClick={() => setSelectedTalent(null)}>
                          <img src={closeButton} alt="Close" className="edit-container" />
                        </button>
                      ) : (
                        <button className='edit-button' onClick={() => { setSelectedTalent(talent.id); }}>
                          <img src={calendarIcon} alt="Calendar" className="edit-container" />
                        </button>
                      )}
                    </span>
                    <button className='edit-button' onClick={() => editTalent(talent)}> <img src={edit} alt="Edit" className="edit-container" /></button>
                  </div>
                </div>
              </>
              )}
              {selectedTalent === talent.id && (
                <div className='talent-item-firstline' >
                  <div className='calendar'>
                    <TalentCalendar talentId={talent.id}  relatedTalents={talent.relatedTalent} />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default TalentListPlaniing;