import React ,{useState} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './../../App.css';
import Select, { components } from 'react-select';
import { collection, getDocs, doc, getDoc, Timestamp, query, where, } from 'firebase/firestore';
import { db } from './../../firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/de';
import edit from './../../images/edit.svg';
import editWhite from './../../images/edit-white.svg';
import { getWeek, startOfWeek, endOfWeek , startOfMonth, endOfMonth} from 'date-fns';
import PropTypes from 'prop-types';
import ListViewAllBookings from './ListViewAllBookings';

const customStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    border: 'none',
  }),
  control: (base) => ({
    ...base,
    width: '400px',
    borderColor: 'black',
    borderWidth: '1px',
    boxShadow: 'none',
    margin: '5px',
    borderRadius: '0px',
    color: 'black',
    fontWeight: "700",
    height: '50px',
    textTransform: 'uppercase',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'black',
    fontWeight: "700"
  }),
  input: (provided) => ({
    ...provided,
    color: 'black',
    fontWeight: "700"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'black' : 'inherit',
    '&:hover': { backgroundColor: state.isSelected ? 'black' : '#f7f7f7' }
  }),
};

// Month names map
const monthNames = {
  'JANUAR': 0,
  'FEBRUAR': 1,
  'MÄRZ': 2,
  'APRIL': 3,
  'MAI': 4,
  'JUNI': 5,
  'JULI': 6,
  'AUGUST': 7,
  'SEPTEMBER': 8,
  'OKTOBER': 9,
  'NOVEMBER': 10,
  'DEZEMBER': 11
};

moment.locale('de')



//import 'moment/locale/de'; // Import the locale you want. In this case, German (Deutschland).

//const localizer = momentLocalizer(moment.locale('de')); // Set the locale to 'de' (German).
class CustomToolbar extends React.Component {
  constructor(props) {
    super(props);
    // We don't need to maintain isWeekView in the state anymore
  }

  render() {
    let { label, currentView } = this.props;

    // Extract the month and start day from the label.
    const month = label.split(" ")[0].toUpperCase();
    const day = label.split(" ")[1].split("–")[0];

    // Construct the date using the extracted day and month.
    let date = new Date();
    date.setMonth(monthNames[month]);
    date.setDate(day);
    date = startOfWeek(date);

    // Get the week number using date-fns
    let weekNumber = getWeek(date);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px' }}>
        <div>
          <button className='button' onClick={() => this.navigate('TODAY')}>today</button>
          <button className='button' onClick={() => this.navigate('PREV')}>Prev</button>
          <button className='button' onClick={() => this.navigate('NEXT')}>Next</button>
        </div>
        <div className='weeknumber-wrapper'>
          {currentView === 'week' ? (
            <label>Week {weekNumber}: <strong>{label}</strong></label>
          ) : (
            <label><strong>{label}</strong></label>
          )}
        </div>
        <div>
          <button 
            className={`button ${currentView === 'week' ? 'button-active' : ''}`} 
            onClick={() => this.view('week')}
          >
            Week
          </button>
          <button 
            className={`button ${currentView === 'agenda' ? 'button-active' : ''}`} 
            onClick={() => this.view('agenda')}
          >
            Day
          </button>
          <button 
            className={`button ${currentView === 'month' ? 'button-active' : ''}`} 
            onClick={() => this.view('month')}
          >
            Month
          </button>
        </div>
      </div>
    );
  }

  navigate = action => {
    const { date, currentView } = this.props;  // current date and view type from the Calendar's props
    let newDate;

    switch (action) {
      case 'NEXT':
        newDate = currentView=== 'month' ? moment(date).add(1, 'month') : moment(date).add(1, 'week');
      
        break;
      case 'PREV':
        newDate = currentView=== 'month' ? moment(date).subtract(1, 'month') : moment(date).subtract(1, 'week');
        break;
      
      case 'TODAY':
        newDate = moment();
        break;
      default:
        newDate = date;
    }

    // Notify the parent component
    this.props.onNavigate(action, newDate.toDate());

    // Notify about the week change
    if (currentView !== 'month') {
      // Notify about the week change only if the view is not 'month'

      this.props.onWeekChange(startOfWeek(newDate.toDate()), endOfWeek(newDate.toDate()));
    }
  }



  view = viewType => {
    this.props.onChangeView(viewType);
  }
  
}

const localizer = momentLocalizer(moment);

// Utility function for debouncing
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

// Helper function to get status color - moved outside class
const getStatusColor = (status) => {
  switch (status) {
    case 'confirmed': return '#15a326';
    case 'new order': return '#ffffff';
    case 'requested': return '#FF8806';
    case 'talentaccepted': return '#3de651';
    case 'declined request': return '#ff3c00';
    case 'optioned': return '#0060ff';
    case 'optionSolved': return '#FFFF00';
    case 'lano': return '#116c11';
    case 'hold': return '#800080';
    case 'Booked': return '#ADD8E6';
    default: return '#606060';
  }
};

class AllBookings extends React.Component {
  constructor(props) {
    super(props);

    // Initialize Firebase functions
    const functions = getFunctions(getApp(), 'europe-west3');
    this.getCalendarEvents = httpsCallable(functions, 'getCalendarEvents');

    // Create debounced version of applyFilters
    this.debouncedApplyFilters = debounce(this.applyFilters, 300);

    this.state = {
      events: [],
      loading: false,
      startOfWeek: startOfWeek(new Date()),
      endOfWeek: endOfWeek(new Date()),
      currentDate: new Date(),
      selectedUserEmail: null,
      userList: [],
      view: 'calendar',
      currentView: 'week',
      selectedClinet: ' ',
      selectedBookingStatus: '',
      selectedSet: null,
      filteredBookings: [],
      setSelectedOptions: [],
      selectedClient: null,
      selectedLocation: null,
      userOptions: [],
      clientOptions: [],
      statusOptions: [],
      locationOptions: [
        { value: ['MET'], label: 'Metzingen (MET)' },
        { value: ['TR4', 'B28'], label: 'Hamburg (TR4, B28)' },
        { value: ['OSB'], label: 'Düsseldorf (OSB)' }
      ],
    };

    // Bind methods
    this.handleUserSelection = this.handleUserSelection.bind(this);
    this.handleBookingStatusSelection = this.handleBookingStatusSelection.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleClientSelection = this.handleClientSelection.bind(this);
    this.switchView = this.switchView.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only update if these specific states change
    return (
      this.state.events.length !== nextState.events.length ||
      this.state.loading !== nextState.loading ||
      this.state.view !== nextState.view ||
      this.state.currentView !== nextState.currentView ||
      this.state.selectedUserEmail !== nextState.selectedUserEmail ||
      this.state.selectedBookingStatus !== nextState.selectedBookingStatus ||
      this.state.selectedClient !== nextState.selectedClient ||
      this.state.selectedSet !== nextState.selectedSet ||
      this.state.selectedLocation !== nextState.selectedLocation ||
      this.state.startOfWeek?.getTime() !== nextState.startOfWeek?.getTime() ||
      this.state.endOfWeek?.getTime() !== nextState.endOfWeek?.getTime() ||
      this.state.currentDate?.getTime() !== nextState.currentDate?.getTime()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // Only fetch bookings if the week range has changed
    if (
      prevState.startOfWeek?.getTime() !== this.state.startOfWeek?.getTime() ||
      prevState.endOfWeek?.getTime() !== this.state.endOfWeek?.getTime()
    ) {
      this.fetchBookings();
    }

    // Only update filters if relevant state has changed
    if (
      prevState.selectedUserEmail !== this.state.selectedUserEmail ||
      prevState.selectedBookingStatus !== this.state.selectedBookingStatus ||
      prevState.selectedClient !== this.state.selectedClient ||
      prevState.selectedSet !== this.state.selectedSet ||
      prevState.selectedLocation !== this.state.selectedLocation
    ) {
      this.debouncedApplyFilters();
    }
  }
  

  componentDidMount() {
    console.log("Component mounting...");
    this.fetchClientsAndSets();
    this.fetchBookings();
    this.fetchUserList();
  }

  populateUserAndClientFilters = () => {
    console.log("Populating filters from events:", this.state.events);
    const { events } = this.state;

    if (!events || events.length === 0) {
      console.log("No events available for populating filters");
      return;
    }

    // Extract unique values
    const uniqueUsers = [...new Set(events.filter(event => event.proposedBy).map(event => event.proposedBy))];
    const uniqueClients = [...new Set(events.filter(event => event.client).map(event => event.client))];
    const uniqueStatuses = [...new Set(events.filter(event => event.status).map(event => event.status))];

    console.log("Extracted values:", { uniqueUsers, uniqueClients, uniqueStatuses });

    // Create options arrays with proper format
    const userOptions = [
      { value: 'all', label: 'All' },
      ...uniqueUsers.map(user => ({ value: user, label: user }))
    ];
    
    const clientOptions = uniqueClients.map(client => ({ 
      value: client, 
      label: client 
    }));

    const statusOptions = uniqueStatuses.map(status => ({ 
      value: status, 
      label: status 
    }));

    // Set options are fixed
    const setOptions = [
      { value: "On Model", label: "On Model" },
      { value: "on Produkt", label: "On Produkt" }
    ];

    console.log("Created options:", {
      userOptions,
      clientOptions,
      statusOptions,
      setOptions
    });

    // Only update state if options have changed
    if (JSON.stringify(this.state.userOptions) !== JSON.stringify(userOptions) ||
        JSON.stringify(this.state.clientOptions) !== JSON.stringify(clientOptions) ||
        JSON.stringify(this.state.statusOptions) !== JSON.stringify(statusOptions)) {
      
      this.setState({
        userOptions,
        clientOptions,
        statusOptions,
        setOptions
      }, () => {
        console.log("State updated with new options:", this.state);
      });
    }
  };

  changeView = (viewType) => {
    this.setState({ currentView: viewType });
  }
  onNavigate = (action, newDate) => {
    // Check the current view to determine how to set the state
    if (this.state.currentView === 'month') {
      // If the current view is month, set the state to the start and end of the month
      this.setState({
        startOfWeek: startOfMonth(newDate),
        endOfWeek: endOfMonth(newDate),
        currentDate: newDate // Update the current date when navigating
      });
    } else {
      // For week view or other views, keep the original behavior
      this.setState({
        startOfWeek: startOfWeek(newDate),
        endOfWeek: endOfWeek(newDate),
        currentDate: newDate
      });
    }
  }
  


  onWeekChange = (start, end) => {
    this.setState({
      startOfWeek: start,
      endOfWeek: end
    }, this.fetchBookings);  // Fetch bookings after the week is changed
  }


  fetchUserList = async () => {
    // Assuming you have a users collection where user documents have a role field
    const userCollectionRef = collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData");

    // Query for superadmin
    const qSuperAdmin = query(userCollectionRef, where("role", "==", "superadmin"));
    const superAdminSnapshot = await getDocs(qSuperAdmin);

    // Query for setCastEditor
    const qSetCastEditor = query(userCollectionRef, where("role", "==", "setCastEditor"));
    const setCastEditorSnapshot = await getDocs(qSetCastEditor);

    // Merge the results
    const userList = [...superAdminSnapshot.docs, ...setCastEditorSnapshot.docs].map(doc => {
      const data = doc.data();
      return { email: data.email, name: data.email };  // Assuming each user has an email and name field
    });
    userList.unshift({ email: 'all', name: 'All' });

    const userOptions = userList.map(user => ({ value: user.email, label: user.name }));

    this.setState({ userList, userOptions });
  };

  fetchClientsAndSets = async () => {
    // Set options are fixed, no need to fetch from database
    const setOptions = [
      { value: "On Model", label: "On Model" },
      { value: "on Produkt", label: "On Produkt" }
    ];

    this.setState({ setOptions });
  };

  handleLocationSelection = (selectedOption) => {
    this.setState({ selectedLocation: selectedOption }, () => {
      this.debouncedApplyFilters();
    });
  };

  handleUserSelection = selectedOption => {
    this.setState({ 
      selectedUserEmail: selectedOption ? selectedOption.value : null 
    }, () => {
      this.debouncedApplyFilters();
    });
  };

  handleBookingStatusSelection = selectedOption => {
    this.setState({ 
      selectedBookingStatus: selectedOption ? selectedOption.value : null 
    }, () => {
      this.debouncedApplyFilters();
    });
  };

  handleClientSelection = selectedOption => {
    this.setState({ selectedClient: selectedOption }, () => {
      this.debouncedApplyFilters();
    });
  };

  handleSetSelection = selectedOption => {
    this.setState({ selectedSet: selectedOption }, () => {
      this.debouncedApplyFilters();
    });
  };

  fetchBookings = async () => {
    this.setState({ loading: true });
    console.log("Starting fetchBookings...");

    try {
      const startDate = this.state.startOfWeek;
      const endDate = this.state.endOfWeek;
      
      const result = await this.getCalendarEvents({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        userEmail: this.state.selectedUserEmail,
        bookingStatus: this.state.selectedBookingStatus
      });

      console.log("Raw events data:", result.data.events);
      
      const events = result.data.events.map(event => {
        const startDate = moment(event.start_date._seconds * 1000);
        const endDate = moment(event.start_date._seconds * 1000);

        return {
          id: event.id,
          title: `${event.client || "Unknown"} - ${event.talent || "No talent"} (${event.status || "Unknown"})`,
          start: startDate,
          end: endDate,
          allDay: true,
          bookingId: event.id,
          client: typeof event.client === 'string' ? event.client : event.client?.label || "Unknown",
          talent: event.talent || "No talent",
          company: event.company || "No company",
          status: event.status || "",
          set: event.set || "",
          kpi: event.kpi || "",
          option: event.option || "",
          proposedBy: event.proposedBy || "",
          position: typeof event.position === 'string' ? event.position : event.position?.label || "",
          startTime: event.startTime || "",
          endTime: event.endTime || "",
          location: event.location || "",
          SonstigeInfo: event.SonstigeInfo || "",
          group: event.group || "",
          groupColor: event.groupColor || "",
          boktitle: event.title || "",
          showDetail: false
        };
      });

      console.log("Processed events:", events);

      // Extract unique values for select options
      const uniqueUsers = [...new Set(events.filter(event => event.proposedBy).map(event => event.proposedBy))];
      const uniqueClients = [...new Set(events.filter(event => event.client).map(event => event.client))];
      const uniqueStatuses = [...new Set(events.filter(event => event.status).map(event => event.status))];

      console.log("Unique values extracted:", {
        users: uniqueUsers,
        clients: uniqueClients,
        statuses: uniqueStatuses
      });

      // Create properly formatted options
      const userOptions = [
        { value: 'all', label: 'All' },
        ...uniqueUsers.map(user => ({ value: user, label: user }))
      ];
      
      const clientOptions = uniqueClients.map(client => ({ 
        value: client, 
        label: client 
      }));

      const statusOptions = uniqueStatuses.map(status => ({ 
        value: status, 
        label: status 
      }));

      console.log("Formatted options:", {
        userOptions,
        clientOptions,
        statusOptions
      });

      // Update state with events and options
      await new Promise(resolve => {
        this.setState({ 
          events,
          filteredBookings: events,
          userOptions,
          clientOptions,
          statusOptions,
          loading: false
        }, resolve);
      });

    } catch (error) {
      console.error('Error fetching bookings:', error);
      this.setState({ 
        loading: false,
        error: error.message 
      });
    }
  };

  applyFilters = () => {
    const { events, selectedBookingStatus, selectedClient, selectedSet, selectedLocation } = this.state;
    let filteredBookings = events;
    
    if (selectedClient) {
        filteredBookings = filteredBookings.filter(booking => 
            booking.client === selectedClient.value || 
            booking.client === selectedClient.label
        );
    }
    
    if (selectedSet) {
        filteredBookings = filteredBookings.filter(booking => 
            booking.set === selectedSet.label || 
            booking.set === selectedSet.value
        );
    }

    if (selectedLocation) {
        filteredBookings = filteredBookings.filter(booking =>
            selectedLocation.value.includes(booking.location)
        );
    }

    // Extract unique values from filtered events
    const uniqueUsers = [...new Set(filteredBookings.filter(event => event.proposedBy).map(event => event.proposedBy))];
    const uniqueClients = [...new Set(filteredBookings.filter(event => event.client).map(event => event.client))];
    const uniqueStatuses = [...new Set(filteredBookings.filter(event => event.status).map(event => event.status))];

    // Create properly formatted options
    const userOptions = [
      { value: 'all', label: 'All' },
      ...uniqueUsers.map(user => ({ value: user, label: user }))
    ];
    
    const clientOptions = uniqueClients.map(client => ({ 
      value: client, 
      label: client 
    }));

    const statusOptions = uniqueStatuses.map(status => ({ 
      value: status, 
      label: status 
    }));

    // Update state with filtered bookings and new options
    this.setState({
      filteredBookings,
      userOptions,
      clientOptions,
      statusOptions
    });
  };

  handleSelectEvent = (event) => {
    const bookingId = event.bookingId;
    // Do something with the bookingId...
  };

  handleEventMouseEnter = (eventId) => {
    const updatedBookings = this.state.filteredBookings.map((event) => {
      if (event.id === eventId) {
        return { ...event, showDetail: true };
      } else {
        return { ...event, showDetail: false };
      }
    });

    this.setState({ filteredBookings: updatedBookings });
  };

  handleEventMouseLeave = (eventId) => {
    const updatedBookings = this.state.filteredBookings.map((event) => {
      if (event.id === eventId) {
        return { ...event, showDetail: false };
      }
      return event;
    });

    this.setState({ filteredBookings: updatedBookings });
  };


  Event = React.memo(({ event }) => {
    const [showDetail, setShowDetail] = useState(false);

    const handleMouseEnter = () => setShowDetail(true);
    const handleMouseLeave = () => setShowDetail(false);

    const actionButtons = (
      <div style={{ 
        position: 'absolute',
        left: '10px',
        right: '10px',
        bottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        {event.groupColor && (
          <div style={{
            backgroundColor: event.groupColor,
            color: '#ffffff',
            padding: '3px 6px',
            borderRadius: '10px',
            fontSize: '0.8em'
          }}>
            {event.group}
          </div>
        )}
        <div style={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowDetail(!showDetail);
            }}
            style={{
              color: '#000',
              textDecoration: 'none',
              padding: '4px 8px',
              backgroundColor: '#fff',
              border: '1px solid #000',
              borderRadius: '4px',
              fontSize: '0.9em',
              cursor: 'pointer'
            }}
          >
            more
          </button>
          <a 
            href={`detail?id=${event.bookingId}`} 
            onClick={(e) => e.stopPropagation()}
            className="edit-button"
            style={{ 
              color: '#000',
              textDecoration: 'none',
              padding: '4px 8px',
              backgroundColor: '#fff',
              border: '1px solid #000',
              borderRadius: '4px',
              fontSize: '0.9em'
            }}
          >
            Edit
          </a>
        </div>
      </div>
    );

    return (
      <div className="my-div"
        style={{ 
          cursor: 'pointer',
          position: 'relative',
          minHeight: '180px',
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          paddingBottom: '50px'
        }}
      >
        {showDetail && (
          <div 
            style={{
              position: 'fixed',
              width: "500px",
              height: "auto",
              maxHeight: "500px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 5px 0px',
              background: '#000000',
              color: '#ffffff',
              padding: '20px',
              zIndex: 9999,
              cursor: 'auto',
              overflowY: 'auto'
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="calendar-inner-cell-container">
              <div>
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                  {` Client `}
                </div>
                {` ${event.client} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Talent `}
                </div>
                {` ${event.talent} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Status `}
                </div>
                {` ${event.status} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Proposed By: `}
                </div>
                {` ${event.proposedBy} `}
              </div>
              <div>
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                  {` Title `}
                </div>
                {` ${event.boktitle} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Set `}
                </div>
                {` ${event.set} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Kpi `}
                </div>
                {` ${event.kpi} `}
              </div>
              <div style={{ flexGrow: '1' }}>
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                  {` Option `}
                </div>
                {` ${event.option} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Start time `}
                </div>
                {` ${moment(event.start).format('DD-MM-YYYY')} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` End time `}
                </div>
                {` ${moment(event.end).format('DD-MM-YYYY')} `}
              </div>
            </div>
            <div style={{ 
              marginTop: '20px', 
              display: 'flex', 
              justifyContent: 'flex-end'
            }}>
              <a 
                href={`detail?id=${event.bookingId}`}
                style={{ 
                  color: '#fff',
                  textDecoration: 'underline',
                  marginRight: '10px'
                }}
              >
                Edit
              </a>
              <button
                onClick={() => setShowDetail(false)}
                style={{ 
                  color: '#fff',
                  background: 'none',
                  border: '1px solid #fff',
                  padding: '5px 10px',
                  cursor: 'pointer'
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
        <div style={{ position: 'relative', marginBottom: '10px' }}>
          <div style={{
            height: '7px',
            width: '7px',
            padding: '10px',
            backgroundColor: getStatusColor(event.status),
            borderRadius: '50%',
            display: 'inline-block',
            float: 'right'
          }} />
        </div>
        <div style={{ flex: 1 }}>
          <span style={{ position: 'relative', color: 'black', whiteSpace: 'pre-line' }}>
            {`${event.boktitle || event.title}\nClient: ${event.client}\nSet: ${event.set}\nKpi: ${event.kpi}\nTalent: ${event.talent} \nOption: ${event.option},\nStatus: ${event.status},`}
          </span>
        </div>
        {actionButtons}
      </div>
    );
  }, (prevProps, nextProps) => {
    return (
      prevProps.event.id === nextProps.event.id &&
      prevProps.event.status === nextProps.event.status
    );
  });

  WeekEvent = React.memo(({ event }) => {
    const [showDetail, setShowDetail] = useState(false);

    const handleMouseEnter = () => setShowDetail(true);
    const handleMouseLeave = () => setShowDetail(false);

    const actionButtons = (
      <div style={{ 
        position: 'absolute',
        left: '10px',
        right: '10px',
        bottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        {event.groupColor && (
          <div style={{
            backgroundColor: event.groupColor,
            color: '#ffffff',
            padding: '3px 6px',
            borderRadius: '10px',
            fontSize: '0.8em'
          }}>
            {event.group}
          </div>
        )}
        <div style={{ display: 'block', gap: '20px', marginLeft: 'auto', width: '100%' }}>
          
          <a 
            href={`detail?id=${event.bookingId}`} 
            onClick={(e) => e.stopPropagation()}
            className="edit-button"
            style={{ 
              color: '#000',
              textDecoration: 'none',
              padding: '4px 8px',
              backgroundColor: '#fff',
              border: 'none',
              fontSize: '0.9em',
            top:'0px'
            }}
          >
            Edit
          </a>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowDetail(!showDetail);
            }}
            style={{
              color: '#000',
              textDecoration: 'none',
              padding: '4px 8px',
              backgroundColor: '#fff',
              border: 'none',
              fontSize: '0.9em',
              cursor: 'pointer'
            }}
          >
            More
          </button>
        </div>
      </div>
    );

    return (
      <div style={{ 
        border: '1px solid black',
        margin: '3px',
        padding: '10px', 
        position: 'relative', 
        color: '#000000', 
        borderTopRightRadius: '10px',
        minHeight: '180px',
        cursor: 'pointer',
        borderRadius: event.set === 'On Model' ? '10px' : '0px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '50px'
      }}>
        {showDetail && (
          <div className='toltip'
            style={{
              position: 'fixed',
              width: "600px",
              height: "auto",
              maxHeight: "500px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: '#000000',
              color: '#ffffff',
              padding: '20px',
              zIndex: 9999,
              cursor: 'auto',
              overflowY: 'auto'
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="calendar-inner-cell-container">
              <div>
                {` ${event.client} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Talent `}
                </div>
                {` ${event.talent} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Status `}
                </div>
                {` ${event.status} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Proposed By: `}
                </div>
                {` ${event.proposedBy} `}
              </div>
              <div>
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                  {` Title `}
                </div>
                {` ${event.boktitle} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Set `}
                </div>
                {` ${event.set} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Kpi `}
                </div>
                {` ${event.kpi} `}
              </div>
              <div style={{ flexGrow: '1' }}>
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                  {` Option `}
                </div>
                {` ${event.option} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                  {` s.info `}
                </div>
                {` ${event.SonstigeInfo} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` Start time `}
                </div>
                {` ${moment(event.start).format('DD-MM-YYYY')} `}
                <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                  {` End time `}
                </div>
                {` ${moment(event.end).format('DD-MM-YYYY')} `}
              </div>
            </div>
            <div style={{ 
              marginTop: '20px', 
              display: 'flex', 
              justifyContent: 'flex-end'
            }}>
              <a 
                href={`detail?id=${event.bookingId}`}
                style={{ 
                  color: '#fff',
                  textDecoration: 'underline',
                  marginRight: '10px'
                }}
              >
                Edit
              </a>
              <button
                onClick={() => setShowDetail(false)}
                style={{ 
                  color: '#fff',
                  background: 'none',
                  border: '1px solid #fff',
                  padding: '5px 10px',
                  cursor: 'pointer'
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
        <div style={{ position: 'relative', marginBottom: '10px' }}>
          <span style={{
            height: '22px',
            width: '22px',
            backgroundColor: getStatusColor(event.status),
            borderRadius: '50%',
            display: 'inline-block',
            position: 'absolute',
            top: '0',
            right: '0'
          }} />
        </div>
        <div style={{ flex: 1 }}>
          <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
            <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap', marginBottom: '4px' }}>{` ${event.client} `}</span>
            <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap', marginBottom: '4px' }}>{` ${event.position} `}</span>
            <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap', marginBottom: '4px' }}>{` ${event.talent} `}</span>
            <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap', marginBottom: '4px' }}>{` ${event.proposedBy} `}</span>
          </span>
        </div>
        {actionButtons}
      </div>
    );
  }, (prevProps, nextProps) => {
    return (
      prevProps.event.id === nextProps.event.id &&
      prevProps.event.status === nextProps.event.status
    );
  });

  MonthEvent = React.memo(({ event }) => {
    console.log("Rendering MonthEvent component with:", event);
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
        case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
        case 'optionSolved':
          statusColor = '#FFFF00';
          break;
      case 'lano':
        statusColor = '#116c11';
        break;
        case 'hold':
          statusColor = '#800080';
          break;
          case 'Booked':
            statusColor = '#ADD8E6';
            break;
      default:
        statusColor = '#606060';
    }
    let borderRadius;
    switch (event.set) {
      case 'On Model':
        borderRadius = '10px';
        break;
      case 'on Produkt':
        borderRadius = '0px';
        break;
    }
    const groupBadge = event.groupColor ? (
      <div style={{
        display: 'inline-block',
        backgroundColor: event.groupColor,
        color: '#ffffff',
        padding: '3px 6px',
        borderRadius: '10px',
        fontSize: '0.8em',
        margin: '0 4px',
        verticalAlign: 'middle'
      }}>        
        {event.group}
      </div>
    ) : null;
    
    return (
      <div style={{ border: '1px solid black', margin:'3px',  padding: '10px', position: 'relative', color: '#000000', borderTopRightRadius: '10px',   minHeight:'70px'     , cursor: 'auto', borderRadius:borderRadius }}
      onClick={(e) => { e.stopPropagation(); this.handleEventMouseEnter(event.id); }}
        onMouseLeave={() => this.handleEventMouseLeave(event.id)}
      >
        {event.showDetail && (
          <div className='toltip'
            style={{
              position: 'absolute',
              width: "600px",
              height: "auto",
              top: "10px",
              left: "10px",
              background: '#000000',
              color: '#ffffff',
              padding: '10px',
              zIndex: 9999,
              cursor: 'auto',
              overflowY: 'auto'
            }}
          >
                            


            <span style={{ position: 'relative', fontWeight: '600', color: '#fff', whiteSpace: 'pre-line' }}>
              <div class="calendar-inner-cell-container">
                <div>

                  {` ${event.client} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Talent `}
                  </div>
                  {` ${event.talent} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Status `}
                  </div>
                  {` ${event.status} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Proposed By: `}
                  </div>
                  {` ${event.proposedBy} `}
                </div>
                <div>
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` Title `}
                  </div>
                  {` ${event.boktitle} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Set `}
                  </div>
                  {` ${event.set} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Kpi `}
                  </div>
                  {` ${event.kpi} `}
                </div>
                <div style={{ flexGrow: '1' }}>
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` Option `}
                  </div>
                  {` ${event.option} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Start time `}
                  </div>
                  {` ${moment(event.start).format('DD-MM-YYYY')} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` End time `}
                  </div>
                  {` ${moment(event.end).format('DD-MM-YYYY')} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` s.info `}
                  </div>
                  {` ${event.SonstigeInfo} `}
              
                 
                </div>
              </div>
              <a href={`detail?id=${event.bookingId}`} style={{ color: 'white', textDecoration: 'underline', fontSize: '1.2em', margin: '10px' }}>
                Edit
              </a>
            </span>

          </div>
        )}
        <div>
          <span style={{
            height: '22px',
            width: '22px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            position: 'absolute',
            top: '10px',
            right: '10px'
          }} />
        </div>
        <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.startTime} - ${event.endTime}`}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.client} `}</span>
          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.position} `}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.talent} `}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.proposedBy} `}</span>

        </span>
        
        <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: '10px', paddingBottom: '10px' }}>
        {groupBadge}
       
        </div>

      </div>
    );
  });

  AgendaEvent = React.memo(({ event }) => {
    console.log("Rendering AgendaEvent component with:", event);
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
      case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
      case 'optionSolved':
        statusColor = '#FFFF00';
        break;
      case 'lano':
        statusColor = '#116c11';
        break;
      case 'hold':
        statusColor = '#800080';
        break;
      case 'Booked':
        statusColor = '#ADD8E6';
        break;
      default:
        statusColor = '#606060';
    }

    return (
      <div style={{ border: '1px solid black', margin:'3px', padding: '10px', position: 'relative', color: '#000000', borderTopRightRadius: '10px', minHeight: '103px', marginBottom: '10px' }}
        onClick={(e) => { e.stopPropagation(); this.handleEventMouseEnter(event.id); }}
        onMouseLeave={() => this.handleEventMouseLeave(event.id)}
      >
        <div>
          <span style={{
            height: '22px',
            width: '22px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            position: 'absolute',
            top: '10px',
            right: '10px'
          }} />
        </div>
        <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
          <div className="calendar-inner-cell-container">
            <div>
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                {` Client `}
              </div>
              {` ${event.client} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Talent `}
              </div>
              {` ${event.talent} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Status `}
              </div>
              {` ${event.status} `}
            </div>
            <div>
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                {` Title `}
              </div>
              {` ${event.boktitle} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Set `}
              </div>
              {` ${event.set} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Kpi `}
              </div>
              {` ${event.kpi} `}
            </div>
          </div>
        </span>
      </div>
    );
  });

  switchView = (viewType) => {
    console.log('Switching view to:', viewType);
    this.setState({ view: viewType });
  };

  render() {
    console.log("Rendering with state:", {
      userOptions: this.state.userOptions,
      clientOptions: this.state.clientOptions,
      statusOptions: this.state.statusOptions,
      selectedUserEmail: this.state.selectedUserEmail,
      selectedClient: this.state.selectedClient,
      selectedBookingStatus: this.state.selectedBookingStatus
    });

    console.log("Rendering with events count:", this.state.events.length);
    console.log("Rendering with filteredBookings count:", this.state.filteredBookings.length);

    if (this.state.loading) {
      return <div className="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>;
    }

    const { userList, selectedUserEmail, loading, events } = this.state;
    const options = userList.map(user => ({
      value: user.email,
      label: user.name
    }));

    const displayEvents = [...this.state.filteredBookings];
    console.log("Final displayEvents length:", displayEvents.length);

    return (
      <section style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        <div className="content-wrapper" style={{flex: 1, overflowY: 'auto', paddingBottom: '30px'}}>
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
              <h1>All Orders</h1>
              <div>
                <a href='allgroups'><button className='big-button'>Groups</button></a>
                <button 
                  className={this.state.view === 'calendar' ? 'big-button' : 'big-button-active'} 
                  onClick={() => this.switchView('list')}>List View</button>
                <button 
                  className={this.state.view === 'calendar' ? 'big-button-active' : 'big-button'} 
                  onClick={() => this.switchView('calendar')}>Calendar View</button>
              </div>
            </div>

            {this.state.view === 'calendar' ? (
              <div>
                <div className='input-container'>
                  <div>
                    <p>Filter by client</p>
                    <Select
                      value={this.state.selectedClient}
                      onChange={this.handleClientSelection}
                      options={this.state.clientOptions}
                      styles={customStyles}
                      isClearable
                    />
                  </div>
                  <div>
                    <p>Filter by set</p>
                    <Select
                      value={this.state.selectedSet}
                      onChange={this.handleSetSelection}
                      options={this.state.setOptions}
                      styles={customStyles}
                      isClearable
                    />
                  </div>
                  <div>
                    <p>Filter by Location</p>
                    <Select
                      value={this.state.selectedLocation}
                      onChange={this.handleLocationSelection}
                      options={this.state.locationOptions}
                      styles={customStyles}
                      isClearable
                    />
                  </div>
                </div>

                <div className='input-container'>
                  <div>
                    <p style={{ marginBottom: '5px' }}>Filter by user</p>
                    <Select
                      value={this.state.userOptions.find(option => option.value === selectedUserEmail) || null}
                      onChange={this.handleUserSelection}
                      options={this.state.userOptions}
                      styles={customStyles}
                      isClearable
                    />
                  </div>
                  <div>
                    <p style={{ marginBottom: '5px' }}>Filter by status</p>
                    <Select
                      value={this.state.statusOptions.find(option => option.value === this.state.selectedBookingStatus) || null}
                      onChange={this.handleBookingStatusSelection}
                      options={this.state.statusOptions}
                      styles={customStyles}
                      isClearable
                    />
                  </div>
                </div>

                <div style={{height: '800px', marginTop: '20px'}}>
                  <Calendar
                    localizer={localizer}
                    events={displayEvents}
                    defaultDate={new Date()}
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: '100%'}}
                    views={['month', 'week', 'agenda']}
                    view={this.state.currentView}
                    onView={this.changeView}
                    onSelectEvent={this.handleSelectEvent}
                    defaultAllDay={true}
                    popup={true}
                    drilldownView="week"
                    length={30}
                    toolbar={true}
                    components={{
                      event: this.Event,
                      week: {
                        event: this.WeekEvent
                      },
                      month: {
                        event: this.MonthEvent
                      },
                      agenda: {
                        event: this.AgendaEvent
                      },
                      toolbar: props => (
                        <CustomToolbar 
                          {...props} 
                          onNavigate={this.onNavigate}
                          onWeekChange={this.onWeekChange} 
                          date={this.state.currentDate}
                          currentView={this.state.currentView}
                          onChangeView={this.changeView}
                        />
                      )
                    }}
                    onNavigate={this.onNavigate}
                    date={this.state.currentDate}
                    elementProps={{ id: 'booking-calendar' }}
                  />
                </div>
              </div>
            ) : (
              <ListViewAllBookings events={this.state.events} authToken={this.props.authToken} />
            )}
          </div>
        </div>
      </section>
    );
  }
}



export default AllBookings;