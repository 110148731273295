import React, { useState, useEffect, useMemo, memo } from "react";
import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "./../../firebaseConfig";
import {
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import Select, { components } from "react-select";
import { customStyles } from "./../../helpers/StyleUtils";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

const MemoizedSelect = memo(Select);

function BookingDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [status, setStatus] = useState("");
  const [propsoedBy, setpropsoedBy] = useState("");
  const bookingId = new URLSearchParams(location.search).get("id");
  const [superAdminOptions, setSuperAdminOptions] = useState([]);
  const [loadTimes, setLoadTimes] = useState({
    bookingFetch: 0,
    superAdminFetch: 0,
    totalTime: 0
  });

  const bookingStatusOptions = useMemo(() => [
    { value: "Booked", label: "booked / Lano task created" },
    { value: "confirmed", label: "confirmed" },
    { value: "new order", label: "new order" },
    { value: "requested", label: "requested" },
    { value: "declined request", label: "declined request" },
    { value: "declined", label: "declined" },
    { value: "canceled", label: "canceled" },
    { value: "optioned", label: "optioned" },
    { value: "optionSolved", label: "option released" },
  ], []);

  useEffect(() => {
    const fetchBookingDetails = async () => {
      if (!bookingId) {
        setNotFound(true);
        console.log("No booking ID found");
        setLoading(false);
        return;
      }
      console.log("Booking ID found:", bookingId);
      const startTime = performance.now();
      console.log(`[PERFORMANCE] Starting data fetch at ${new Date().toISOString()}`);
      
      try {
        console.log(`[PERFORMANCE] Fetching booking details for ID: ${bookingId}`);
        
        // Use Cloud Function instead of direct Firestore query
        const functions = getFunctions(getApp(), 'europe-west3');
        const getBookingDetailsFunction = httpsCallable(functions, 'getBookingDetailseditEdit');
        
        const bookingFetchStart = performance.now();
        const result = await getBookingDetailsFunction({ bookingId });
        const bookingFetchEnd = performance.now();
        const bookingFetchTime = bookingFetchEnd - bookingFetchStart;
        
        console.log(`[PERFORMANCE] Booking data fetch took ${bookingFetchTime.toFixed(2)}ms`);

        if (!result.data || !result.data.booking) {
          console.log("[PERFORMANCE] Booking not found");
          setNotFound(true);
          setLoading(false);
          return;
        }

        const bookingData = result.data.booking;
        console.log(`[PERFORMANCE] Booking data processed at ${(performance.now() - startTime).toFixed(2)}ms`);
        setBookingDetails(bookingData);
        setStatus(bookingStatusOptions.find(option => option.value === bookingData.status));

        // Set superadmin data from the cloud function response
        if (result.data.superAdmins && result.data.superAdmins.length > 0) {
          console.log(`[PERFORMANCE] SuperAdmin data received with ${result.data.superAdmins.length} admins`);
          setSuperAdminOptions(result.data.superAdmins);
          setpropsoedBy(result.data.superAdmins.find(option => option.value === bookingData.proposedBy));
        }

        const totalTime = performance.now() - startTime;
        setLoadTimes({
          bookingFetch: bookingFetchTime,
          superAdminFetch: 0, // No separate fetch needed now
          totalTime: totalTime
        });
      } catch (error) {
        console.error("[PERFORMANCE] Error fetching booking details:", error);
        setNotFound(true);
      } finally {
        const totalTime = performance.now() - startTime;
        console.log(`[PERFORMANCE] Total data loading time: ${totalTime.toFixed(2)}ms`);
        setLoading(false);
      }
    };

    fetchBookingDetails();
  }, [bookingId, bookingStatusOptions]);

  const updateStatus = async () => {
    if (status) {
      const bookingRef = doc(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
        bookingId
      );
      await updateDoc(bookingRef, { status: status.value });
      alert("Status updated successfully");
    } else {
      alert("Please select a status before updating");
    }
  };

  const updatePropsedBy = async () => {
    if (propsoedBy) {
      const bookingRef = doc(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
        bookingId
      );
      await updateDoc(bookingRef, { proposedBy: propsoedBy.value });
      alert("Propsed By user updated successfully");
    } else {
      alert("Please select a user before updating");
    }
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const poposeClick = async () => {
    const bookingRef = doc(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
      bookingId
    );
    const bookingSnap = await getDoc(bookingRef);
    if (bookingSnap.exists() && !bookingSnap.data().isBeingProcessed) {
      // If the order is not being processed, update it to being processed
      await updateDoc(bookingRef, {
        isBeingProcessed: true,
        processingBy: localStorage.getItem("userEmail"),
      });
      navigate(
        `/admin/propose?bookingId=${bookingSnap.bookingId}&group=${bookingSnap.group}`
      );
    } else {
      alert(
        "This order is being processed by another user." +
          bookingRef.data().isBeingProcessed
      );
    }
  };

  const handleError = (error) => {
    console.error("Error in BookingDetailsPage:", error);
    setLoading(false);
    setNotFound(true);
  };

  // Render the booking details if available
  return (
    <section>
      {loading ? (
        <div className="content-wrapper">Loading Order details...</div>
      ) : notFound ? (
        <div className="content-wrapper">No such Order exists.</div>
      ) : bookingDetails ? (
        <div>
          <div className="content-wrapper">
            <h1 style={{ marginBottom: "50px" }}>Order Details</h1>
            <div style={{ fontSize: '12px', color: '#666', marginBottom: '20px' }}>
              Booking fetch time: {loadTimes.bookingFetch.toFixed(2)}ms | 
              SuperAdmin fetch time: {loadTimes.superAdminFetch.toFixed(2)}ms | 
              Total load time: {loadTimes.totalTime.toFixed(2)}ms
            </div>
          </div>
          <div className="content-wrapper content-wrapper-talent-list">
            <div className="list-tasks-header">
              <div style={{ flexBasis: "350px" }}>Client</div>
              <div style={{ flexBasis: "150px" }}>Date</div>
              <div style={{ flexBasis: "150px" }}>option</div>
              <div style={{ flexBasis: "150px" }}>position</div>
              <div style={{ flexBasis: "150px" }}>set</div>
              <div style={{ flexBasis: "150px" }}>status</div>
              <div style={{ flexBasis: "150px" }}>start</div>
              <div style={{ flexBasis: "150px" }}>end</div>
            </div>
            <div className="list-tasks-item">
              <div style={{ flexBasis: "350px" }}>
                {bookingDetails.client.label}
              </div>
              <div style={{ flexBasis: "150px" }}>
                {
                  // Handle different timestamp formats
                  typeof bookingDetails.end_date.toDate === 'function'
                    ? bookingDetails.end_date.toDate().toLocaleDateString().split("T")[0]
                    : new Date(bookingDetails.end_date.seconds * 1000).toLocaleDateString().split("T")[0]
                }
              </div>
              <div style={{ flexBasis: "150px" }}>{bookingDetails.option}</div>
              <div style={{ flexBasis: "150px" }}>
                {bookingDetails.position.label}
              </div>
              <div style={{ flexBasis: "150px" }}>{bookingDetails.set}</div>
              <div style={{ flexBasis: "150px" }}>{bookingDetails.status}</div>
              <div style={{ flexBasis: "150px" }}>
                {bookingDetails.startTime}
              </div>
              <div style={{ flexBasis: "150px" }}>{bookingDetails.endTime}</div>
            </div>
          </div>
          <div className="content-wrapper" style={{ marginTop: "30px" }}>
            <button
              className="button"
              onClick={() => navigate(`../propose?bookingId=${bookingId}&group=${bookingDetails.group}`)}
            >
              propose other talent
            </button>
            <button
              className="button"
              onClick={() => navigate(`../edit?bookingId=${bookingId}`)}
            >
              edit all details
            </button>
          </div>
          <div className="content-wrapper" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
    <div style={{ marginTop: "50px", width: "28%",}}> 
    <p style={{ padding:'10px'}}>Status:</p>
      <MemoizedSelect
        value={status}
        onChange={handleStatusChange}
        options={bookingStatusOptions}
        isClearable
        placeholder="Select Status"
        styles={customStyles}
      />
      </div>
      <div style={{ marginTop: "90px", width: "28%",}}> 
      <button className="button" onClick={updateStatus}>
        Update Status
      </button>
    </div>
    <div style={{ marginTop: "50px", width: "28%" }}> 
    <p style={{ padding:'10px'}}>Propose By:</p>
      <Select
        value={propsoedBy}
        onChange={setpropsoedBy}
        options={superAdminOptions}
        isClearable
        placeholder="Select Proposed user"
        styles={customStyles}
      />
      </div>
      <div style={{marginTop: "90px", width: "28%", }}> 
      <button className="button" onClick={updatePropsedBy}>
        Update Propose By
      </button>
    </div>
</div>

        </div>
      ) : null}
    </section>
  );
}
export default BookingDetailsPage;
